import React from 'react'

import Container from 'components/Container'

import * as S from './styled'

const FooterData = () => {
  return (
    <S.FooterData>
      <Container>
        ©2024 okkhor.org
        <a className="link" href="/policies">Privacy Policy</a>
        <a className="link" href="/tos">Terms of Use</a>
        <a className="link" href="https://github.com/arif25169" target="_blank" rel="noopener noreferrer">GitHub</a>
      </Container>
    </S.FooterData>
  )
}

export default FooterData
